import {
    COMMON_SETTING_MODAL_OPEN,
    COMMON_SETTING_MODAL_CLOSE,
    OPEN_LOGIN_MODAL,
    CLOSE_LOGIN_MODAL,
    OPEN_FORGOT_PASSWORD_MODAL,
    CLOSE_FORGOT_PASSWORD_MODAL,
    SMS_VERIFICATION_MODAL_CLOSE,
    SMS_VERIFICATION_MODAL_OPEN,
    ITEM_MODAL_OPEN,
    ITEM_MODAL_CLOSE,
    SUB_MENU_MODAL_OPEN,
    SUB_MENU_MODAL_CLOSE,
    MODAL_OPEN,
    MODAL_CLOSE,
    TRANSACTION_MODAL_OPEN,
    TRANSACTION_MODAL_CLOSE,
    DECLINE_REASON_MODAL_OPEN,
    DECLINE_REASON_MODAL_CLOSE,
    OPEN_HEADER_MODAL,
    CLOSE_HEADER_MODAL
} from '../constants';

export default function modalStatus(state = {}, action) {
    switch (action.type) {
        case COMMON_SETTING_MODAL_OPEN:
            return {
                ...state,
                isCommonSettingModalOpen: action.isCommonSettingModalOpen
            }

        case COMMON_SETTING_MODAL_CLOSE:
            return {
                ...state,
                isCommonSettingModalOpen: action.isCommonSettingModalOpen
            }

        case OPEN_LOGIN_MODAL:
            return {
                ...state,
                isLoginModalOpen: action.isLoginModalOpen,
                isForgotPasswordModalOpen: action.isForgotPasswordModalOpen
            }

        case CLOSE_LOGIN_MODAL:
            return {
                ...state,
                isLoginModalOpen: action.isLoginModalOpen
            }


        case OPEN_FORGOT_PASSWORD_MODAL:
            return {
                ...state,
                isLoginModalOpen: action.isLoginModalOpen,
                isForgotPasswordModalOpen: action.isForgotPasswordModalOpen
            }

        case CLOSE_FORGOT_PASSWORD_MODAL:
            return {
                ...state,
                isForgotPasswordModalOpen: action.isForgotPasswordModalOpen
            }

        case SMS_VERIFICATION_MODAL_OPEN:
            return {
                ...state,
                isSmsVerificationModalOpen: action.payload.isSmsVerificationModalOpen,
                formType: action.payload.formType
            }

        case SMS_VERIFICATION_MODAL_CLOSE:
            return {
                ...state,
                isSmsVerificationModalOpen: action.payload.isSmsVerificationModalOpen,
                formType: undefined
            }

        case ITEM_MODAL_OPEN:
            return {
                ...state,
                isItemModalOpen: action.isItemModalOpen,
                menuId: action.menuId,
                subMenuId: action.subMenuId
            }

        case ITEM_MODAL_CLOSE:
            return {
                ...state,
                isItemModalOpen: action.isItemModalOpen,
            }

        case SUB_MENU_MODAL_OPEN:
            return {
                ...state,
                isSubMenuModalOpen: action.isSubMenuModalOpen,
                menuId: action.menuId
            }

        case SUB_MENU_MODAL_CLOSE:
            return {
                ...state,
                isSubMenuModalOpen: action.isSubMenuModalOpen,
            }

        case MODAL_OPEN:
            return {
                ...state,
                [action.payload.name]: action.payload.status,
                data: [action.payload.data]
            }

        case MODAL_CLOSE:
            return {
                ...state,
                [action.payload.name]: action.payload.status,
                data: []
            }
        case TRANSACTION_MODAL_OPEN:
            return {
                ...state,
                isTransactionModalOpen: action.isTransactionModalOpen,
            }

        case TRANSACTION_MODAL_CLOSE:
            return {
                ...state,
                isTransactionModalOpen: action.isTransactionModalOpen,
            }

        case DECLINE_REASON_MODAL_OPEN:
            return {
                ...state,
                isDeclineModalOpen: action.isDeclineModalOpen,
                bookingId: action.bookingId,
                currentPage: action.currentPage
            }

        case DECLINE_REASON_MODAL_CLOSE:
            return {
                ...state,
                isDeclineModalOpen: action.isDeclineModalOpen
            }

        case OPEN_HEADER_MODAL:
            return {
                ...state,
                [action.payload.modalType]: action.payload.actionValue
            };

        case CLOSE_HEADER_MODAL:
            return {
                ...state,
                [action.payload.modalType]: action.payload.actionValue
            };

        default:
            return {
                ...state
            }
    }
}