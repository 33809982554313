/* eslint-disable global-require */
import { adminRoutes } from './adminRoutes'; // Site Admin Routes
import { commonRoutes } from './commonRoutes'; // Common Routes like home and main pages
import { shopRoutes } from './shopRoutes'; // Restaurant module routes

let children = [];
children = children.concat(commonRoutes, adminRoutes, shopRoutes); // Merging different module routes
// Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
children.push({
  path: '(.*)',
  load: () => import(/* webpackChunkName: 'not-found' */ './not-found')
});

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children,

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'}`;
    route.description = route.description || '';

    return await route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
